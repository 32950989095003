import validate from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45user_45global from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/middleware/01.authUser.global.ts";
import _02_45licence_45global from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/middleware/02.licence.global.ts";
import _03_45redirection_45guard_45global from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/middleware/03.redirectionGuard.global.ts";
import manifest_45route_45rule from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45user_45global,
  _02_45licence_45global,
  _03_45redirection_45guard_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}