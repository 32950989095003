import { default as indexHqiPvwm1FIMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account/custom/index.vue?macro=true";
import { default as packagesJA268Itz9jMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account/custom/packages.vue?macro=true";
import { default as parts7fZX1CjYQ1Meta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account/custom/parts.vue?macro=true";
import { default as custom4g1RyRalryMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account/custom.vue?macro=true";
import { default as indexNeCdbya0FTMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account/index.vue?macro=true";
import { default as ratesOXCx2qpameMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account/rates.vue?macro=true";
import { default as subscriptionXw1ZrgqdPrMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account/subscription.vue?macro=true";
import { default as accountDeAOB3Vu4TMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account.vue?macro=true";
import { default as _91id_935Ep2HUsfiPMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/activite/clients/edit/[id].vue?macro=true";
import { default as indexqJVxJvjSH5Meta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/activite/clients/index.vue?macro=true";
import { default as _91id_9323izfdDxF7Meta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93nKrly9Yf5lMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/activite/commandes/[id].vue?macro=true";
import { default as indexGzjYQApXqbMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculesZpow9lZ2tNMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_93bUjOMq7flhMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/boutique/[title]-[id].vue?macro=true";
import { default as index44J4p9MAoEMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/boutique/index.vue?macro=true";
import { default as productsCswEczQLKZMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/boutique/products.vue?macro=true";
import { default as indexyQWhZ19yHdMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/index.vue?macro=true";
import { default as configurationtd95r9Ps6vMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as indexvY3Hof0D9rMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/maintenance/index.vue?macro=true";
import { default as planmZXUpsrYGEMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/maintenance/plan.vue?macro=true";
import { default as works6GlCORpUE4Meta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenanceGX2c6dKoWrMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/maintenance.vue?macro=true";
import { default as manualsaWFDKYqQ75Meta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/manuals.vue?macro=true";
import { default as timesEMotblkLnXMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/times.vue?macro=true";
import { default as catalogz3WoW8Ns1ZMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog.vue?macro=true";
import { default as commandedoEUFyvodWMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/commande.vue?macro=true";
import { default as _91tab_93yMex9dJdFeMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/devis/[tab].vue?macro=true";
import { default as _91id_93wBTg46mHh6Meta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93y2w22hX2KiMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdfDAIAChyA3jMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/imprimer-pdf.vue?macro=true";
import { default as indexVLeYd78JXPMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/index.vue?macro=true";
import { default as login7KdeW7SPtAMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/login.vue?macro=true";
import { default as panierOUHaZWxoSHMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/panier.vue?macro=true";
import { default as indexKQv0alqhfuMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/pneumatique/index.vue?macro=true";
import { default as products5tp0lDQqjnMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/pneumatique/products.vue?macro=true";
import { default as pneumatique8fXW2aHSZjMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/pneumatique.vue?macro=true";
import { default as maintenanceqlxJaPa1N3Meta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/print/maintenance.vue?macro=true";
import { default as productsH3AjX2bz0mMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/products.vue?macro=true";
import { default as signupBNWxdolYRIMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/signup.vue?macro=true";
import { default as tableau_45de_45bordUKVhybNJAmMeta } from "/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountDeAOB3Vu4TMeta?.name,
    path: "/account",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account.vue"),
    children: [
  {
    name: custom4g1RyRalryMeta?.name,
    path: "custom",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account/custom.vue"),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account/custom/index.vue")
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account/custom/packages.vue")
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account/custom/parts.vue")
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account/index.vue")
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account/rates.vue")
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/account/subscription.vue")
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/activite/clients/edit/[id].vue")
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/activite/clients/index.vue")
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/activite/clients/show/[id].vue")
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/activite/commandes/[id].vue")
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/activite/commandes/index.vue")
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/activite/vehicules.vue")
  },
  {
    name: "boutique-title-id",
    path: "/boutique/:title()-:id()",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/boutique/[title]-[id].vue")
  },
  {
    name: "boutique",
    path: "/boutique",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/boutique/index.vue")
  },
  {
    name: "boutique-products",
    path: "/boutique/products",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/boutique/products.vue")
  },
  {
    name: catalogz3WoW8Ns1ZMeta?.name,
    path: "/catalog",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog.vue"),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/index.vue")
  },
  {
    name: maintenanceGX2c6dKoWrMeta?.name,
    path: "maintenance",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/maintenance.vue"),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/maintenance/configuration.vue")
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/maintenance/index.vue")
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/maintenance/plan.vue")
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/maintenance/works.vue")
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/manuals.vue")
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/catalog/times.vue")
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/commande.vue")
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/devis/[tab].vue")
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/devis/edit/[id].vue")
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/devis/show/[id].vue")
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdfDAIAChyA3jMeta || {},
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/imprimer-pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login7KdeW7SPtAMeta || {},
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/login.vue")
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/panier.vue")
  },
  {
    name: pneumatique8fXW2aHSZjMeta?.name,
    path: "/pneumatique",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/pneumatique.vue"),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/pneumatique/index.vue")
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/pneumatique/products.vue")
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenanceqlxJaPa1N3Meta || {},
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/print/maintenance.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupBNWxdolYRIMeta || {},
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/signup.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("/var/www/vhosts/magnet-piecesauto.fr/dev-app.magnet-piecesauto.fr/pages/tableau-de-bord.vue")
  }
]